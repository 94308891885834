import * as React from 'react';
import { Image, PrimaryButton } from '@fluentui/react';
import { SplashPageProps, SplashPageState } from './SplashPage.types';
import { Styles } from './SplashPage.styles';
import { DxpModal, DxpModalStyles, renderContentStringWithLink, Colors } from '@csmsce/msx-components';
import { UserSettingService, TelemetryService, SdkEvents } from '@csmsce/msx-sdk';
import { ButtonProps, SplashPageConfigurationTimeoutOptions } from '@csmsce/msx-types';

export class SplashPage extends React.Component<SplashPageProps, SplashPageState> {

    private interval: number = undefined!;

    constructor(props: SplashPageProps) {
        super(props);
        this.state = {
            isOpen: !this._alreadyShownSplashPage(),
            timeRemaining: props.config.timeoutOptions?.duration || 0
        };
        if (props.config.timeoutOptions) {
            this.interval = window.setInterval(this._onInterval, 1000);
        }
    }

    public render() {
        let {
            config
        } = this.props;
        let modalStyles = this._getModalStyles(config.timeoutOptions);
        return (
            <DxpModal
                height={'responsive'}
                isOpen={this.state.isOpen}
                onDismiss={config.timeoutOptions ? undefined : this._dismissSplash}
                styles={modalStyles}
                isBlocking={!!config.timeoutOptions}
            >
                {this._renderDxpModalContent()}
            </DxpModal>
        );
    }

    /**
     * Render splash page content
     */
    private _renderDxpModalContent(): React.ReactNode {
        let {
            config
        } = this.props;
        return (
            <div className={Styles.modalContent}>
                <div className={Styles.modalLeftSide}>
                    <h1 className={Styles.modalHeaderText}>{config.header}</h1>
                    <div className={Styles.modalContentText}>
                        {renderContentStringWithLink(config.content, this._onLinkClick)}
                    </div>
                    <div className={Styles.modalButton}>
                        {config.buttons.map((button, index) => {
                            return (
                                <PrimaryButton key={`primaryButton-${index}`} onClick={() => this._onButtonClick(button)}>
                                    {button.buttonText}
                                </PrimaryButton>);
                        })}
                    </div>
                    {config.timeoutOptions && <>
                        <p>
                            You will be redirected automatically in {this.state.timeRemaining} second{this.state.timeRemaining === 1 ? '' : 's'}.
                        </p>
                        <p>
                            If you are not redirected automatically, <a href={config.timeoutOptions.redirectUrl}>click here</a>.
                        </p>
                    </>}
                </div>
                <div className={Styles.modalRightSide}>
                    <Image src={config.imageUrl} alt={'culture illustration background image'} />
                </div>
            </div>
        );
    }

    private _onButtonClick = (button: ButtonProps) => {
        TelemetryService.trackUserAction(
            SdkEvents.App.ClickButtonInSplashPage,
            this.props.telemetryContext,
            {
                actionDetails: 'User clicked button in splash page',
                selectionDetails: button.buttonText,
                _buttonText: button.buttonText,
                _buttonUrl: button.buttonUrl
            }
        );
        window.open(button.buttonUrl);
    }

    private _onLinkClick = () => {
        TelemetryService.trackUserAction(
            SdkEvents.App.ClickContentLinkInSplashPage,
            this.props.telemetryContext,
            {
                actionDetails: 'User clicked link in splash page',
                selectionDetails: ''
            }
        );
    }

    /**
     * create custom dxpmodalStyle for splashPage
     */
    private _getModalStyles = (timeoutOptions: SplashPageConfigurationTimeoutOptions | undefined): Partial<DxpModalStyles> => ({
        headerContainer: {
            height: '44px',
            padding: '0px 0px 44px 0px'
        },
        contentContainer: {
            padding: 0,
            backgroundColor: Colors.Modal.DarkHeader
        },
        closeButton: timeoutOptions && { display: 'none' }
    });

    /**
    * Check whether we already shown this splash page before
    * @param splashPageConfiguration splash page configuration
    */
    private _alreadyShownSplashPage() {
        let splashPageConfiguration = this.props.config;
        // always show non-dismissable splashes
        if (splashPageConfiguration.timeoutOptions) {
            return false;
        }
        let splashPageStorageKeys = UserSettingService.get('splashPageStorageKeys');
        return splashPageStorageKeys && splashPageStorageKeys.includes(splashPageConfiguration.storageKey);
    }

    private _dismissSplash = () => {
        let splashPageStorageKeys = UserSettingService.get('splashPageStorageKeys') || [];
        TelemetryService.trackUserAction(
            SdkEvents.App.CloseSplashPage,
            this.props.telemetryContext,
            {
                actionDetails: 'User closed splash page',
                selectionDetails: ''
            }
        );

        // store splash page storage key in userSettings
        UserSettingService.update(
            'splashPageStorageKeys',
            [...splashPageStorageKeys, this.props.config.storageKey],
            this.props.telemetryContext
        ).catch((error: Error) => {
            TelemetryService.trackException(error, this.props.telemetryContext);
            TelemetryService.trackWarning('Failed to Update UserSettings', this.props.telemetryContext);
        });
        this.setState({ isOpen: false });
    }

    private _onInterval = () => {
        if (this.state.timeRemaining === 0) {
            window.clearInterval(this.interval);
            window.location.assign(this.props.config.timeoutOptions!.redirectUrl);
        } else {
            this.setState(state => ({ timeRemaining: state.timeRemaining - 1 }));
        }
    }
}