import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ErrorBoundary, ErrorScope } from '@csmsce/msx-components';
import { TelemetryService } from '@csmsce/msx-sdk';
import Portal from './portal';

let debugMode = process.env.ENVIRONMENT !== 'prod';

/**
 * Embed script for azure internet analyzer, we need to execute this script once per page load
 * Document: https://docs.microsoft.com/en-us/azure/internet-analyzer/internet-analyzer-embed-client#run-on-page-load
 */
let tag = document.createElement('script');
tag.type = 'text/javascript';
tag.src = process.env.INTERNET_ANALYZER_SCRIPT_URL!;
document.body.appendChild(tag);

ReactDOM.render(
    <ErrorBoundary errorScope={ErrorScope.Global} debugMode={debugMode} onException={TelemetryService.trackException}>
        <Portal />
    </ErrorBoundary>,
    document.getElementById('root') as HTMLElement
);