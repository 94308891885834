import { mergeStyleSets, IStyle } from '@fluentui/react';
import { Colors } from '@csmsce/msx-components';

export const Styles = mergeStyleSets({
    modalContent: {
        height: '43.4vh',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'row',
        background: Colors.Modal.DarkHeader,
        paddingBottom: '44px' // headercontainer height
    } as IStyle,
    modalLeftSide: {
        display: 'flex',
        height: '100%',
        paddingLeft: '5.85vw',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center'
    } as IStyle,
    modalRightSide: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '8.5vw',
        paddingRight: '7.1vw',
        height: '100%'
    } as IStyle,
    modalHeaderText: { marginBottom: '12px' } as IStyle,
    modalContentText: {
        paddingTop: '0px',
        paddingBottom: '24px'
    } as IStyle,
    modalButton: { marginBottom: '28.76px' } as IStyle
});