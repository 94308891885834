import { getWizardTitleStyles, wizardBreadcrumbStyles } from '../wizard/index';
import type {
  IFullPageWizardStyleProps,
  IFullPageWizardStyles,
} from './full-page-wizard.types';

const componentName = 'FullPageWizard';

export const getFullPageWizardStyles = (
  props: IFullPageWizardStyleProps,
): IFullPageWizardStyles => {
  const { isNarrow, isLoading, theme } = props;

  return {
    root: {
      height: isNarrow && !isLoading ? 'unset' : '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    headerContainer: [
      {
        borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`,
      },
    ],
    footerContainer: {
      borderTop: `1px solid ${theme.semanticColors.bodyDivider}`,
    },
    title: getWizardTitleStyles({ theme, componentName, isNarrow }),
    subComponentStyles: {
      wizard: {
        subwayNavSection: {
          borderRight: `1px solid ${theme.semanticColors.bodyDivider}`,
        },
      },
      breadcrumb: { ...wizardBreadcrumbStyles, root: { margin: '16px 0px 16px 0px' } },
    },
  };
};
