import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as LoDash from 'lodash';
import * as DxpSDK from '@csmsce/msx-sdk';
import * as DxpComponents from '@csmsce/msx-components';
import * as DxpChartComponents from '@csmsce/msx-chartcomponents';
import * as FluentUi from '@fluentui/react';
import * as OfficeFabric from '@fluentui/react';
import * as DxpRedux from '@csmsce/msx-redux';
import * as ReactRedux from 'react-redux';
import * as Moment from 'moment';
import * as Highcharts from 'highcharts';
import * as GQLTag from 'graphql-tag';
import * as GridViewRenderer from '@csmsce/msx-platform/lib/Views/GridView/GridViewRenderer';
import * as ViewRenderer from '@csmsce/msx-platform/lib/Views/ViewRenderer';
import * as Coherence from '@coherence-design-system/controls';

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
    }
}

export function InitGlobalModules() {
    window['MSX_EXTERNAL_React'] = React;
    window['MSX_EXTERNAL_ReactDOM'] = ReactDOM;
    window['MSX_EXTERNAL_DxpSDK'] = DxpSDK;
    window['MSX_EXTERNAL_DxpComponents'] = DxpComponents;
    window['MSX_EXTERNAL_DxpChartComponents'] = DxpChartComponents;
    window['MSX_EXTERNAL_MsxPlatform_GVR'] = GridViewRenderer;
    window['MSX_EXTERNAL_MsxPlatform_VR'] = ViewRenderer;
    window['MSX_EXTERNAL_DxpRedux'] = DxpRedux;
    window['MSX_EXTERNAL_OfficeFabric'] = OfficeFabric;
    window['MSX_EXTERNAL_FluentUi'] = FluentUi;
    window['MSX_EXTERNAL_LoDash'] = LoDash;
    window['MSX_EXTERNAL_ReactRedux'] = ReactRedux;
    window['MSX_EXTERNAL_Highcharts'] = Highcharts;
    window.Highcharts = Highcharts;
    window['MSX_EXTERNAL_Moment'] = Moment;
    window['MSX_EXTERNAL_GQLTag'] = GQLTag;
    window['MSX_EXTERNAL_Coherence'] = Coherence;
}