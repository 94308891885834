import { LayoutService, NavigationAttributes, NavigationEntityType, NavigationState } from '@csmsce/msx-sdk';
import { StandardView } from '@csmsce/msx-types';

export function searchParamsToLower(params: URLSearchParams): Record<string, string> {
    let newParams: Record<string, string> = {};
    params.forEach((value, key) => newParams[key.toLowerCase()] = value);
    return newParams;
}

const fallbackView: StandardView = {
    name: 'Home',
    id: 'FAKE-INVALID',
    configType: 'view',
    icon: 'ConstructionCone',
    layoutType: 'gridLayout',
    mexs: [],
    visible: true,
    url: 'home',
    viewType: 'AdminDefined',
    displayName: 'Home',
    isGlobal: false,
    status: 'Completed',
    createdBy: 'Admin',
    createdOn: 0,
    description: 'No view found!',
    _etag: undefined!,
    _ts: 0
};

export function getDefaultView(views: StandardView[]): StandardView {
    let selectedView = views.find(v => Boolean(v.isDefault));
    // if we still don't have one, just show the first visible view
    if (!selectedView) {
        selectedView = views.find(v => Boolean(v.visible));
        if (!selectedView) {
            // ultimate fallback
            return fallbackView;
        }
    }
    return selectedView;
}

/**
 * Intermediate object for storing navigation information extracted from the url
 */
export interface NavInfo {
    /**
     * The current view OR the view we are overlaying on top of
     */
    view: StandardView;
    /**
     * the latest pushed view; the name of the view we are overlaying on top of the current view
     */
    latestPushedView: string | undefined;
    /**
     * The prefix that should be applied to the `navViewUrl` if the current view is a child of a nested view
     */
    nestedParentPath: string;
    /**
     * The query params extracted from the url
     */
    queryParams: Record<string, string>;
}

/**
 * Gets the selected view and other info based on navigaton url. 
 * 
 * If the navigation url doesn't match the url of any views in the layout, it returns the default view
 * of the layout.
 * 
 * Also returns the legacy entity value if found.
 * @param views list of root loaded views for look up
 * @param path url to convert
 */
export function getNavInfoFromUrl(url: string, views: StandardView[]): NavInfo {
    let parsed = new URL(url);
    let queryParams = searchParamsToLower(parsed.searchParams);
    let path: string = getUrlPathWithoutLayoutOverride(parsed.pathname);
    let selectedView: StandardView | undefined = undefined;
    let legacyEntity: string | undefined = undefined;
    let latestPushedView: string | undefined = undefined;
    let nestedParentPath = '';

    // If the search url isn't just a slash, look for it.
    if (!window['mexextshell_viewname'] && path !== '/') {
        let segments = path.split('/');
        let index: number;
        for (index = 1; index < segments.length; index++) {
            let segment = segments[index];
            let viewListToSearch = views;
            // for nested views, search the child views instead
            if (selectedView && selectedView.layoutType === 'nestedLayout') {
                nestedParentPath = `${nestedParentPath}${selectedView.url}/`;
                viewListToSearch = selectedView.childViews;
            }
            selectedView = viewListToSearch.find(x => x.url.toLowerCase() === segment.toLowerCase());
            // if we haven't found the view yet, keep looking through the url
            if (selectedView && selectedView.layoutType !== 'nestedLayout') {
                // if we've found the view, ignore any further segments.
                // nested layouts require us to keep looking at further segments
                break;
            }
        }
        /**
         * If there is more to the path than just a slash, the first segment past the current url is LatestPushedViewName, or possibly
         * the legacy entity value
         */
        const extraSegment = segments[index + 1];
        if (extraSegment) {
            // LEGACY: if the extra segment is a number, 
            const asNumber = Number(extraSegment);
            if (!Number.isNaN(asNumber)) {
                legacyEntity = extraSegment;
            } else if (extraSegment !== 'home') {
                // Handle https://msxportal.microsoft.com/a360/home legacy url
                latestPushedView = extraSegment;
            }
        }
    }

    // if selectedView is undefined or we somehow tried to navigate directly to a nestedLayout, get the default view instead
    if (!selectedView || selectedView.layoutType === 'nestedLayout') {
        nestedParentPath = '';
        selectedView = getDefaultView(views);
    }

    // in order to remove references to the legacy reference, we pretend it's already in the queryParams
    if (legacyEntity && !queryParams['id']) {
        queryParams['id'] = legacyEntity;
    }

    if (window['mexextshell_viewname']) {
        latestPushedView = window['mexextshell_viewname'];
    }
    return {
        view: selectedView,
        latestPushedView: latestPushedView,
        nestedParentPath: nestedParentPath,
        queryParams: queryParams
    };
}

/**
 * Gets legacy navigation attributes from a NavInfo object
 * @param navInfo the NavInfo to convert
 * @param propertyBag the current navigation state
 */
export const getLegacyNavAttributesFromNavInfo = (navInfo: NavInfo, propertyBag: NavigationState): NavigationAttributes => {
    const queryParams = navInfo.queryParams;

    // Get guid from path
    let guid: string | undefined = queryParams['id'];
    // Get entity type from path
    let et: string | undefined = queryParams['et'];
    /**
     * If the query parameters contain et, set et value to entityType. 
     * Example: 'accountDetails?et=engagement' will set the entityType to 'engagement'
     * If query parameters dont contain et, we will just set entityType to be undefined
     */
    let entityType: NavigationEntityType | undefined = (et && et in NavigationEntityType) ? NavigationEntityType[et as keyof typeof NavigationEntityType] : undefined;

    let navigationView = navInfo.view;
    let entityValue = guid;

    return {
        navigationUrl: navInfo.nestedParentPath + navInfo.view.url,
        navigationView: navigationView.displayName,
        navigationViewId: navigationView.id,
        entityValue: entityValue,
        entityType: entityType,
        propertyBag: propertyBag,
        queryParams: queryParams
    };
};

/**
 * @param navigationUrl `new URL(window.location.href).pathname`
 * @returns the current navigation URL, WITHOUT the layoutOverride, if present
 */
export function getUrlPathWithoutLayoutOverride(navigationUrl: string) {
    let layoutOverride = LayoutService.activeLayoutOverride();
    if (layoutOverride) {
        navigationUrl = navigationUrl.substring(layoutOverride.length + 1) || '/';
    }
    return navigationUrl;
}

/**
 * Searches the list of views (recursively, if encountering a nested view), and returns the view with the chosen
 * url or name. Also returns the final url of the view, including any parent nested view urls.
 * @param by 
 * @param value 
 * @param views 
 * @returns the view or undefined, followed by its url path
 */
export function findViewBy(by: 'url' | 'name', value: string, views: StandardView[]): [StandardView | undefined, string] {
    for (let view of views) {
        if (view[by] === value) {
            return [view, view.url];
        }
        if (view.layoutType === 'nestedLayout') {
            const [nestedView, nestedPath] = findViewBy(by, value, view.childViews);
            if (nestedView) {
                return [nestedView, `${view.url}/${nestedPath}`];
            }
        }
    }
    return [undefined, ''];
}

/**
 * Constant for the default nav context state value. the browser types this as an `any`, but we don't
 * want our users to have to handle `undefined` cases
 */
export const DefaultNavState: NavigationState = {};