import { IProgressIndicatorStyles, mergeStyles } from '@fluentui/react';

export const progressContainer = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
});

export const progressBarStyle: Partial<IProgressIndicatorStyles> = {
    itemDescription: { textAlign: 'center' },
    itemName: {
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '30px'
    },
    itemProgress: { width: '400px' },
    root: {
        textAlign: 'center',
        lineHeight: '40px',
        alignItems: 'center'
    }
};