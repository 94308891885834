import type { IPanelProps, IRenderFunction } from '@fluentui/react';
import { classNamesFunction, PanelType, ScreenWidthMinLarge } from '@fluentui/react';
import { M365Breadcrumb } from '@m365-admin/m365-breadcrumb';
import { M365Panel } from '@m365-admin/m365-panel';
import type { FC } from 'react';
import * as React from 'react';

import { getStepToShow, shouldWizardBeNarrow } from '../utilities/index';
import { Wizard } from '../wizard/index';
import type {
  IPanelWizardProps,
  IPanelWizardStyleProps,
  IPanelWizardStyles,
} from './panel-wizard.types';

const getClassNames = classNamesFunction<IPanelWizardStyleProps, IPanelWizardStyles>();

const { useState, useEffect } = React;

/**
 * Base component for PanelWizard
 */
export const PanelWizardBase: FC<IPanelWizardProps> = (props: IPanelWizardProps) => {
  const { breadcrumbProps } = props;

  const _onRenderTitle: IRenderFunction<IPanelProps> = (navPanelProps) => {
    if (breadcrumbProps) {
      return (
        <div className={_classNames.titleElementContainer}>
          <M365Breadcrumb
            {...breadcrumbProps}
            styles={_classNames.subComponentStyles.breadcrumb}
          />
        </div>
      );
    } else {
      // We still need the div to ensure proper spacing of elements around the title.
      return (
        <div className={_classNames.titleElementContainer}>
          {navPanelProps?.headerText}
        </div>
      );
    }
  };

  const {
    onRenderTitle = _onRenderTitle,
    panelProps,
    wizardProps,
    styles,
    theme,
  } = props;
  const [isWizardNarrow, setIsWizardNarrow] = useState(false);
  const [isWindowNarrow, setIsWindowNarrow] = useState(
    shouldWizardBeNarrow(window.innerWidth),
  );
  const _classNames = getClassNames(styles, {
    isWindowNarrow: isWindowNarrow,
    theme: theme!,
    isWizardNarrow: isWizardNarrow,
    isLoading: !!wizardProps.isLoading,
  });

  useEffect(() => {
    function handleWindowResize() {
      if (isWindowNarrow !== shouldWizardBeNarrow(window.innerWidth)) {
        setIsWindowNarrow(shouldWizardBeNarrow(window.innerWidth));
      }
    }
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const _renderNullHeader = (): null => {
    return null;
  };

  const _onRenderNavigationContent = (
    navPanelProps: IPanelProps,
    defaultRender: IRenderFunction<IPanelProps>,
  ): JSX.Element => {
    if (isWindowNarrow) {
      return <></>;
    }

    return (
      <>
        {onRenderTitle(navPanelProps, _onRenderTitle)}
        {defaultRender(navPanelProps)}
      </>
    );
  };

  const _onRenderFooter = (): JSX.Element => {
    const step = getStepToShow(wizardProps);

    return <>{step.footerElement}</>;
  };

  const onWizardIsNarrowChanged = (newNarrowValue: boolean): void => {
    if (isWizardNarrow !== newNarrowValue) {
      setIsWizardNarrow(newNarrowValue);
    }

    if (wizardProps?.onIsNarrowChanged) {
      wizardProps.onIsNarrowChanged(newNarrowValue);
    }
  };

  if (panelProps?.headerText && breadcrumbProps) {
    console.warn(
      'It looks like you have passed in both panelProps.headerText and breadcrumbProps. panelProps.headerText will be overridden by breadcrumbProps. Please choose one or the other.',
    );
  }

  if (breadcrumbProps?.styles) {
    console.warn(
      'It looks like you passed in breadcrumb.styles. These will be overwritten. Please use PanelWizardProps.styles.subComponentStyles.breadcrumb instead.',
    );
  }

  return (
    <M365Panel
      hasCloseButton={false}
      customWidth={isWindowNarrow ? ScreenWidthMinLarge.toString() : undefined}
      type={isWindowNarrow ? PanelType.custom : PanelType.large}
      onRenderNavigationContent={isWindowNarrow ? undefined : _onRenderNavigationContent}
      isFooterAtBottom={true}
      onRenderFooterContent={isWindowNarrow ? undefined : _onRenderFooter}
      onRenderHeader={_renderNullHeader}
      {...panelProps}
      styles={_classNames.subComponentStyles.panel}
    >
      <>
        {isWindowNarrow && panelProps && onRenderTitle(panelProps)}

        <Wizard
          calloutProps={isWindowNarrow ? { minPagePadding: 0 } : {}}
          onIsNarrowChanged={onWizardIsNarrowChanged}
          styles={_classNames.subComponentStyles.wizard}
          {...wizardProps}
        />
        {isWindowNarrow && panelProps && _onRenderFooter()}
      </>
    </M365Panel>
  );
};
