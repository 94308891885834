"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoleExperience = exports.MexSize = exports.MexImageTypes = exports.MexAudience = exports.MexDataSource = exports.MexCategory = exports.MexDisplayType = exports.Role = void 0;
var Role;
(function (Role) {
    Role["Owner"] = "Owner";
    Role["Contributor"] = "Contributor";
    Role["Reader"] = "Reader";
})(Role = exports.Role || (exports.Role = {}));
/**
 * Determines what section in a view the mex is allowed to be in.
 */
var MexDisplayType;
(function (MexDisplayType) {
    MexDisplayType["Hero"] = "Hero";
    MexDisplayType["Grid"] = "Grid";
    MexDisplayType["Page"] = "Page";
    MexDisplayType["Floating"] = "Floating";
    MexDisplayType["Settings"] = "Settings";
    MexDisplayType["TopNavBar"] = "TopNavBar";
})(MexDisplayType = exports.MexDisplayType || (exports.MexDisplayType = {}));
var MexCategory;
(function (MexCategory) {
    MexCategory["Accounts"] = "Accounts";
    MexCategory["Activities"] = "Activities";
    MexCategory["Admin"] = "Admin";
    MexCategory["Attainment"] = "Attainment";
    MexCategory["Consumption"] = "Consumption";
    MexCategory["Engagements"] = "Engagements";
    MexCategory["Forecast"] = "Forecast";
    MexCategory["Generic"] = "Generic";
    MexCategory["Leads"] = "Leads";
    MexCategory["News"] = "News";
    MexCategory["Opportunities"] = "Opportunities";
    MexCategory["POC"] = "POC";
})(MexCategory = exports.MexCategory || (exports.MexCategory = {}));
/**
 * Determines the Data source of the mex.
 */
var MexDataSource;
(function (MexDataSource) {
    MexDataSource["SMDP"] = "SMDP";
    MexDataSource["Apportal"] = "Apportal";
    MexDataSource["Bing"] = "Bing";
    MexDataSource["MINT"] = "MINT";
    MexDataSource["MSN"] = "MSN";
    MexDataSource["MSXD365"] = "MSX D365";
    MexDataSource["MSXInsights"] = "MSX Insights";
    MexDataSource["Recommendations"] = "Recommendations";
    MexDataSource["Other"] = "Other";
})(MexDataSource = exports.MexDataSource || (exports.MexDataSource = {}));
/**
 * The intended audience for the mex.
 */
var MexAudience;
(function (MexAudience) {
    MexAudience["All"] = "All";
    MexAudience["CustomerSuccess"] = "Customer Success";
    MexAudience["CSSPreSales"] = "CSS pre-sales";
    MexAudience["DemandResponse"] = "Demand Response";
    MexAudience["FieldSales"] = "Field Sales";
    MexAudience["Leaders"] = "Leaders";
    MexAudience["Partner"] = "Partner";
    MexAudience["Retail"] = "Retail";
    MexAudience["Services"] = "Services";
})(MexAudience = exports.MexAudience || (exports.MexAudience = {}));
/**
 * Image to show in the mex catalog
 */
var MexImageTypes;
(function (MexImageTypes) {
    MexImageTypes["List"] = "List";
    MexImageTypes["DataGrid"] = "DataGrid";
    MexImageTypes["LineChart"] = "LineChart";
    MexImageTypes["BarChart"] = "BarChart";
    MexImageTypes["DonutChart"] = "DonutChart";
    MexImageTypes["KPI"] = "KPI";
    MexImageTypes["People"] = "People";
    MexImageTypes["PowerBI"] = "PowerBI";
})(MexImageTypes = exports.MexImageTypes || (exports.MexImageTypes = {}));
/** Enum used to list posible sizes of a Mex in a grid view */
var MexSize;
(function (MexSize) {
    MexSize["Size1x1"] = "1x1";
    MexSize["Size2x1"] = "2x1";
    MexSize["Size3x1"] = "3x1";
    MexSize["Size1x2"] = "1x2";
    MexSize["Size2x2"] = "2x2";
    MexSize["Size3x2"] = "3x2";
    MexSize["Size1x3"] = "1x3";
    MexSize["Size2x3"] = "2x3";
    MexSize["Size3x3"] = "3x3";
})(MexSize = exports.MexSize || (exports.MexSize = {}));
/**
 * enum for the different types of Experiences a role can have
 */
var RoleExperience;
(function (RoleExperience) {
    RoleExperience["Default"] = "Default";
    RoleExperience["CustomerSuccess"] = "Customer Success";
    RoleExperience["Finance"] = "Finance";
    RoleExperience["Retail"] = "Retail";
    RoleExperience["AE"] = "AE";
    RoleExperience["SSP"] = "SSP";
    RoleExperience["TSP"] = "TSP";
    RoleExperience["DemandResponse"] = "Demand Response";
    RoleExperience["ContentManagement"] = "Content Management";
})(RoleExperience = exports.RoleExperience || (exports.RoleExperience = {}));
