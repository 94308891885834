import * as React from 'react';
import {
    HeaderType, MexTelemetryContext, MSXPlatformComponent,
    LayoutService, SdkEvents, TelemetryService, NavigationEvent, NavigationState, TelemetryEvent, ConfigService
} from '@csmsce/msx-sdk';
import {
    DefaultNavState, findViewBy, getLegacyNavAttributesFromNavInfo, getNavInfoFromUrl, searchParamsToLower
} from './RoutingUtils';
import { App } from '../App/App';
import * as v4 from 'uuid/v4';
import { TeachingBubbleQueue } from '@csmsce/msx-components';

const replaceEvent: TelemetryEvent = {
    eventName: 'Event.App.Navigation.Replace',
    usageEventName: 'PageNavigation',
    usageEventType: 'UserAction'
};
const pushEvent: TelemetryEvent = {
    eventName: 'Event.App.Navigation.Push',
    usageEventName: 'PageNavigation',
    usageEventType: 'UserAction'
};
const popEvent: TelemetryEvent = {
    eventName: 'Event.App.Navigation.Pop',
    usageEventName: 'PageNavigation',
    usageEventType: 'UserAction'
};
const systemPopEvent: TelemetryEvent = {
    eventName: 'Event.App.Navigation.UserPop',
    usageEventName: 'PageNavigation',
    usageEventType: 'UserAction'
};

const appRouterParserMexTelemetryContext: MexTelemetryContext = {
    ViewName: 'N/A',
    ViewInstanceId: 'N/A',
    MexName: 'Platform/AppRouterParser',
    MexInstanceId: 'N/A',
    ComponentId: MSXPlatformComponent.id,
    ComponentName: MSXPlatformComponent.name
};

interface AppRouterState {
    viewInstanceId: string;
}

interface AppRouterProps {
    experience: string;
}

export class AppRouter extends React.Component<AppRouterProps, AppRouterState> {
    constructor(props: AppRouterProps) {
        super(props);
        // Initial url string
        let urlString = new URL(window.location.href);
        // Get params from path
        let params = searchParamsToLower(urlString.searchParams);

        // Log the source of this instance of AppRouterParser. If appname is not provided, default to MSXPortal
        let navigationSource = params['appname'];
        const viewInstanceId = v4();
        // resume session for new view instance
        TelemetryService.resumeSession(viewInstanceId);
        TelemetryService.trackUserAction(
            SdkEvents.App.Navigation,
            appRouterParserMexTelemetryContext,
            {
                actionDetails: 'Navigation',
                selectionDetails: window.location.href,
                '_navigationSource': navigationSource ? navigationSource : 'MSXPortal',
                '_url': window.location.href,
                viewInstanceId: viewInstanceId
            }
        );

        this.state = { viewInstanceId: viewInstanceId };
    }

    /**
     * Handles the legacy nav event and also pushes the results to the window history
     * @param event 
     */
    private setNavAttributes = (event: NavigationEvent) => {
        this.handleNavEvent(event);
    }

    /**
    * Legacy navigation event handler
    */
    private handleNavEvent = (event: NavigationEvent) => {
        let {
            navigationUrl,
            propertyBag = {},
            queryParams
        } = event;

        // for backwards-compatibility, put the slash on if it's missing
        if (!navigationUrl.startsWith('/')) {
            navigationUrl = '/' + navigationUrl;
        }
        let layoutOverride = LayoutService.activeLayoutOverride();
        if (layoutOverride) {
            navigationUrl = '/' + layoutOverride + navigationUrl;
        }
        let newUrl = new URL(navigationUrl, window.location.origin);
        /* loop through queryParams and construct the query string */
        if (queryParams) {
            for (const key in queryParams) {
                newUrl.searchParams.append(key.toLowerCase(), queryParams[key]);
            }
        }
        let locationUrl = newUrl.pathname + newUrl.search;

        TelemetryService.cancelSession(this.state.viewInstanceId);
        TeachingBubbleQueue._ClearQueue();
        window.history.pushState(propertyBag, '', locationUrl);
        this.setState({ viewInstanceId: v4() });
    }

    componentDidMount() {
        // add listener for back/forward button presses
        window.addEventListener('popstate', this.onPopstate);
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.onPopstate);
    }

    /**
     * callback when user hits the back or forward button
     * @param ev 
     */
    private onPopstate = (_ev: PopStateEvent) => {
        TelemetryService.cancelSession(this.state.viewInstanceId);
        TeachingBubbleQueue._ClearQueue();
        const oldViewInstanceId = this.state.viewInstanceId;
        const newViewInstanceId = v4();
        TelemetryService.trackEvent(systemPopEvent, appRouterParserMexTelemetryContext, {
            _prevViewInstanceId: oldViewInstanceId,
            _newViewInstanceId: newViewInstanceId
        });
        this.setState({ viewInstanceId: newViewInstanceId });
    }

    render() {
        const layout = LayoutService.getLayout(appRouterParserMexTelemetryContext);
        const views = layout.views;
        const topNavBarMexs = ConfigService.get('TopNavBarMexs');
        const navInfo = getNavInfoFromUrl(window.location.href, views);
        const state = window.history.state || DefaultNavState;
        let navAttributes = getLegacyNavAttributesFromNavInfo(navInfo, state);
        let headerType = HeaderType.full;
        if (window['mexextshell_hostname']) {
            headerType = HeaderType.chromeless;
        }
        if (navInfo.queryParams['chromeless'] === '1') {
            headerType = HeaderType.chromeless;
        } else if (navInfo.queryParams['chromelesswithheader'] === '1') {
            headerType = HeaderType.brandingOnly;
        }
        return (
            <App
                headerType={headerType}
                navigationContext={{
                    navigationAttributes: navAttributes,
                    setNavAttributes: this.setNavAttributes,
                    currentView: navInfo.latestPushedView || navInfo.view.name,
                    queryArgs: navInfo.queryParams,
                    state: state,
                    isRoot: !navInfo.latestPushedView,
                    pop: this.pop,
                    push: this.push,
                    replace: this.replace,
                    pushToRoot: this.pushToRoot
                }}
                experience={this.props.experience}
                viewInstanceId={this.state.viewInstanceId}
                topNavBarMexs={topNavBarMexs}
            />
        );
    }

    private push = (view: string, queryArgs: Record<string, string>, state: NavigationState, telemetryContext: MexTelemetryContext) => {
        const leftNavViews = LayoutService.getLayout(telemetryContext).views;
        // is new view in left nav?
        const [leftNavView, leftNavUrl] = findViewBy('name', view, leftNavViews);
        let latestPushedViewName: string | undefined = undefined;
        let navViewUrl: string;
        if (leftNavView) {
            // view is in left nav, we reset `latestPushedViewName`, and swap it out for that view's 
            navViewUrl = leftNavUrl;
        } else {
            latestPushedViewName = view;
            const curNavInfo = getNavInfoFromUrl(window.location.href, leftNavViews);
            navViewUrl = curNavInfo.nestedParentPath + curNavInfo.view.url;
        }

        let newUrlPath = navViewUrl;
        const layoutOverride = LayoutService.activeLayoutOverride();
        if (layoutOverride) {
            newUrlPath = `${layoutOverride}/${newUrlPath}`;
        }
        if (latestPushedViewName) {
            newUrlPath = `${newUrlPath}/${latestPushedViewName}`;
        }
        let newUrl = new URL(newUrlPath, window.location.origin);
        /* loop through queryArgs and construct the query string */
        for (const key in queryArgs) {
            newUrl.searchParams.append(key.toLowerCase(), queryArgs[key]);
        }

        TelemetryService.cancelSession(this.state.viewInstanceId);
        TeachingBubbleQueue._ClearQueue();


        const oldViewInstanceId = this.state.viewInstanceId;
        const newViewInstanceId = v4();


        TelemetryService.trackEvent(pushEvent, telemetryContext, {
            _view: view,
            _args: JSON.stringify(queryArgs),
            _state: JSON.stringify(state),
            _type: latestPushedViewName ? 'overlay' : 'root',
            _prevViewInstanceId: oldViewInstanceId,
            _newViewInstanceId: newViewInstanceId
        });

        window.history.pushState(state, '', newUrl.pathname + newUrl.search);
        this.setState({ viewInstanceId: newViewInstanceId });
    }

    private pushToRoot = (queryArgs: Record<string, string>, state: NavigationState, telemetryContext: MexTelemetryContext) => {
        const curNavInfo = getNavInfoFromUrl(window.location.href, LayoutService.getLayout(telemetryContext).views);
        this.push(curNavInfo.view.name, queryArgs, state, telemetryContext);
    }

    private replace = (queryArgs: Record<string, string>, state: NavigationState, telemetryContext: MexTelemetryContext) => {
        let newUrl = new URL(window.location.href);
        newUrl.search = '';
        /* loop through queryArgs and construct the query string */
        for (const key in queryArgs) {
            newUrl.searchParams.append(key.toLowerCase(), queryArgs[key]);
        }

        window.history.replaceState(state, '', newUrl.pathname + newUrl.search);

        this.forceUpdate();

        TelemetryService.trackEvent(replaceEvent, telemetryContext, {
            _args: JSON.stringify(queryArgs),
            _state: JSON.stringify(state)
        });
    }

    private pop = (telemetryContext: MexTelemetryContext) => {
        const oldViewInstanceId = this.state.viewInstanceId;
        const newViewInstanceId = v4();
        TelemetryService.cancelSession(oldViewInstanceId);
        TelemetryService.trackEvent(popEvent, telemetryContext, {
            _prevViewInstanceId: oldViewInstanceId,
            _newViewInstanceId: newViewInstanceId
        });
        window.history.back();
        this.setState({ viewInstanceId: newViewInstanceId });
    }
}