import { IDs, PromptConstants } from "./../UiConstants";
import * as Configuration from "./../../Configuration/Configuration";
import {
	ISubmitResponse, renderCVSurvey, IURLParam, PageType, IResponse,
	ISurveyPayloadAdditionalInfo, ILoggerProps, EventType, IContextParam, QuestionType, ISurveyQuestionAnswer,
} from "gatecrash";
import * as Api from "@ms-ofb/officefloodgatecore/dist/src/Api/Api";
import { Transporter } from "./../../Transport/Transport";
import * as Logging from "./../../Logging/Logging";
import { isNOU } from "@ms-ofb/officefloodgatecore/dist/src/Utils";
import { customerVoiceSurveyUX } from "../../Constants";
import { IOnDismissDelegate } from "../../IOnDismissDelegate";

/**
 * Callback when the CV Survey dialog is dismissed
 */
let onDismiss: IOnDismissDelegate;
let pageType: string;
let onDismissFlag: boolean = false;
let promptResponseLogValidated: boolean = false;

/**
 * Function to show Customer Voice survey experience
 */
export function createCVSurvey(contextParam: IContextParam, urlParam: IURLParam, onClose: () => void): void {
	onDismiss = function (submitted: boolean) {
		Configuration.get().getFloodgateInitOptions().onDismiss(
			Configuration.get().getFloodgateSurvey().getCampaignId(),
			submitted
		);
		onClose();
	};

	const parentContainer = document.createElement("div");
	parentContainer.id = IDs.ToastContainer;
	parentContainer.style.position = "fixed";
	parentContainer.style.bottom = "56px";
	parentContainer.style.right = "19px";
	parentContainer.style.zIndex = "999";
	document.body.appendChild(parentContainer);
	document.getElementById(IDs.ToastContainer).style.display = "none";
	renderCVSurvey(IDs.ToastContainer, onCVSubmit, onCVCancel, onCVLoaded, logGatecrashEvent, contextParam, urlParam);
}

// Setting Rating field for SubmitData
export function getRating(values: ISurveyQuestionAnswer): number {
	return parseInt(values?.answerValue[0], 10) - 1;
}

// Setting QuestionValue field for SubmitData
export function getQuestionValue(values: ISurveyQuestionAnswer): string {
	return values?.questionValue;
}

// Setting AnswerValue field for SubmitData
export function getAnswerValue(values: ISurveyQuestionAnswer): string {
	return isNOU(values?.answerValue[0]) ? values?.answerValue[0] : `${values?.answerValue[0]}`;
}

export interface IFormattedData {
	rating: number;
	comment?: string;
	email?: string;
	ratingQuestion?: string;
	promptQuestion?: string;
	promptResponse?: string;
	commentTitle?: string;
}

/* Traverses the CV Survey respnose object array and creates props for OCV Payload; 
 * on the face of it though it is O(N^2) runtime complexity, 
 * given the number of pages are only in finite number 
 * (wouldn't expect more than 10 pages at most), this should still run in constant time. 
 */
export function formatSubmitData(submitResponse: ISubmitResponse): IFormattedData {

	let rating: number;
	let comment: string;
	let email: string;
	let ratingQuestion: string;
	let promptQuestion: string;
	let promptResponse: string;
	let commentTitle: string;

	submitResponse.pageInfo.forEach(currentPage => {

		currentPage.questionAnswers.forEach(values => {

			switch (values?.questionType) {

				case QuestionType.Rating:

					if (currentPage.pageType === PageType.SurveyRating || currentPage.pageType === PageType.SinglePage) {
						rating = getRating(values);
						ratingQuestion = getQuestionValue(values);

						if (submitResponse.responseType === EventType.FormPartialResponseSubmitted ||
							submitResponse.responseType === EventType.FormResponseSubmitted) {
							pageType = currentPage.pageType;
						}

					}

					break;
				case QuestionType.Prompt:

					promptQuestion = getQuestionValue(values);
					promptResponse = getAnswerValue(values);

					break;

				case QuestionType.Verbatim:
					if (currentPage.pageType === PageType.Verbatim || currentPage.pageType === PageType.SinglePage) {
						commentTitle = getQuestionValue(values);
						comment = getAnswerValue(values);

						if (submitResponse.responseType === EventType.FormResponseSubmitted) {
							pageType = currentPage.pageType;
						}
					}

					break;

				case QuestionType.Email:
					email = getAnswerValue(values);

					break;

				default:

			}

		});

	});

	const formattedData: IFormattedData = {
		rating,
		ratingQuestion,
		email,
		comment,
		commentTitle,
		promptQuestion,
		promptResponse,
	};

	return formattedData;
};

function updateExtendedManifestData(transporter: Transporter, formattedData: IFormattedData): string {

	const extendedManifestData = JSON.parse(transporter.getExtendedManifestData()) as any;
	extendedManifestData.surveyStrings = JSON.stringify({
		prompt: { title: "", question:  formattedData?.promptQuestion, yesButtonText: "YES", noButtonText: "NO" },
		rating: [{ question: formattedData?.ratingQuestion, options: [] }],
		comment: [{ question: formattedData?.commentTitle }],
	});
	transporter.setExtendedManifestData(JSON.stringify(extendedManifestData));
	return extendedManifestData;

}

function updateAppData(): string {

	let appData = Configuration.get().getCommonInitOptions().applicationGroup.appData;

	// To differentiate CV survey responses from other surveys given the flight object in OCV gets trimmed, this is a workaround for now.
	const cvSurveyInfo: ISurveyPayloadAdditionalInfo = {
		additionalInfo: {
			SurveyDescription: customerVoiceSurveyUX,
			Fligtht: Configuration.get().getCVSurveyFlights(),
		},
	};

	appData = isNOU(appData) ? JSON.stringify(cvSurveyInfo) : JSON.stringify(Object.assign(JSON.parse(appData), cvSurveyInfo));

	return appData;
}

function setTransporterValues(transporter: Transporter, formattedData: IFormattedData): Transporter {
	transporter.setManifestValues(Configuration.get().getFloodgateSurvey().getJsonElements());
	updateExtendedManifestData(transporter, formattedData);

	if (formattedData.email) {
		transporter.setEmail(formattedData.email);
	}
	const appData = updateAppData();
	transporter.setAppData(appData);
	transporter.setClientFeedbackId(Configuration.get().getFloodgateSurvey().getPreGeneratedClientFeedbackId());

	return transporter;
}

/**
 * Call-back function when user sends feedback from Customer Voice survey experience
 */
function onCVSubmit(submitResponse: ISubmitResponse): void {
	try {

		const formattedData = formatSubmitData(submitResponse);
		const { rating, comment, promptResponse } = formattedData;

		// Check for prompt pageType
		if (!isNOU(promptResponse)) {
			if (promptResponseLogValidated === false) {
				promptResponseLogValidated = true;

				return logTelemetryEvent(promptResponse === PromptConstants.No ?
					Logging.EventIds.SURVEY_UI_PROMPT_USERCLOSED : Logging.EventIds.SURVEY_UI_PROMPT_CLICKED);
			}
			if (promptResponseLogValidated === true && promptResponse === PromptConstants.No) {
				return;
			}
		}

		// clientfeedback id to overwrite
		let transporter: Transporter = new Transporter(
			Configuration.get().getCommonInitOptions().environment,
			Api.ISurvey.Type[Configuration.get().getFloodgateSurvey().getSurveyType()],
			Configuration.get().getCommonInitOptions().appId,
			"Survey",
			Configuration.get().getCommonInitOptions().applicationGroup,
			Configuration.get().getCommonInitOptions().telemetryGroup,
			Configuration.get().getCommonInitOptions().webGroup,
		);

		Configuration.get().getFloodgateSurvey().setValues(Number(rating), comment ? comment : "");
		setTransporterValues(transporter, formattedData);
		transporter.submit();

		// Toggle OnDismissFlag when data is submitted to OCV
		if (submitResponse.responseType === EventType.FormResponseSubmitted ||
			submitResponse.responseType === EventType.FormPartialResponseSubmitted) {
			onDismissFlag = true;
		}

		logTelemetryEvent(
			pageType === PageType.SinglePage || pageType === PageType.SurveyRating
				? Logging.EventIds.SURVEY_UI_FORM_SUBMIT
				: Logging.EventIds.SURVEY_UI_FORM_SECONDARY_SUBMIT
		);

	} catch (ex) {
		logTelemetryEvent(Logging.EventIds.SURVEY_UI_FORM_SUBMIT_ERROR);
	}
}

/**
 * Call-back function when user cancels feedback from Customer Voice survey experience
 */
function onCVCancel(data: IResponse): void {
	switch (data.responseEventType) {
		case EventType.FormClosed:
			logTelemetryEvent(Logging.EventIds.SURVEY_UI_FORM_USERCLOSED);
			break;
		case EventType.FormAutoDismissed:
			logTelemetryEvent(Logging.EventIds.SURVEY_UI_PROMPT_AUTODISMISSED);
			break;
		case EventType.FormResponseCanceled:
			logTelemetryEvent(Logging.EventIds.SURVEY_UI_FORM_USERCLOSED);
			break;
		default:
			logTelemetryEvent(Logging.EventIds.SURVEY_UI_FORM_NO_RETURN_TYPE);
			break;
	}

	onDismiss(onDismissFlag);
}

/**
 * Call-back function when user Customer Voice survey is loaded and ready for showing to the user.
 */
function onCVLoaded(data: IResponse): void {
	document.getElementById(IDs.ToastContainer).style.display = "inherit";
	data.hasSurveyPromptShown === true ? logTelemetryEvent(Logging.EventIds.SURVEY_UI_PROMPT_SHOWN) :
		logTelemetryEvent(Logging.EventIds.SURVEY_UI_FORM_SHOWN);
}

/**
 * Call-back logging function for gatecrash errors.
 */
function logGatecrashEvent(response: ILoggerProps): void {
	const configuration = Configuration.get();
	const logCustomerProps: Logging.ICustomProperties = {
		CampaignId: configuration.getFloodgateSurvey().getCampaignId(),
		SurveyId: configuration.getFloodgateSurvey().getId(),
		SurveyType: configuration.getFloodgateSurvey().getSurveyType(),
		ErrorMessage: response.CustomProperties.ErrorMessage ? response.CustomProperties.ErrorMessage : null,
		Message: response.CustomProperties.Message ? response.CustomProperties.Message : null,
	};

	logTelemetryEvent(response.EventId, logCustomerProps);
}

// Log Telemetry for Customer Voice survey funnel.
function logTelemetryEvent(eventId: Logging.IEventId, customProperties?: Logging.ICustomProperties): void {
	const configuration = Configuration.get();
	let logCustomerProps: Logging.ICustomProperties;
	if (!customProperties) {
		logCustomerProps = {
			CampaignId: configuration.getFloodgateSurvey().getCampaignId(),
			SurveyId: configuration.getFloodgateSurvey().getId(),
			SurveyType: configuration.getFloodgateSurvey().getSurveyType(),
			ClientFeedbackId: Configuration.get().getFloodgateSurvey().getPreGeneratedClientFeedbackId(),
			CVFormId: configuration.getFloodgateSurvey().getCVSurveyFormId(),
		};
	} else {
		logCustomerProps = customProperties;
	}

	Logging.getLogger().logEvent(
		eventId,
		Logging.LogLevel.Critical,
		logCustomerProps
	);
}
