import * as React from 'react';
import {
    css, MessageBar, MessageBarButton, Spinner, SpinnerSize, ThemeProvider, loadTheme
} from '@fluentui/react';
import { CoherenceCustomizations, CoherenceTheme } from '@coherence-design-system/styles';
import {
    INavigationContext, HeaderType, LayoutService,
    MexTelemetryContext, TelemetryService, UserMappingService,
    MSXPlatformComponent, SdkEvents, ConfigService, ShellContext, ShellNavigationModeFlags, resetSupportContext, ShellButton, NavigationContext, findTopSameOriginWindow
} from '@csmsce/msx-sdk';
import {
    ErrorBoundary, ErrorMessage, ErrorScope, renderContentStringWithLink, TeachingBubbleQueueRenderer
} from '@csmsce/msx-components';
import { TopNavBarConnect } from '@csmsce/msx-platform/lib/TopNavBar/TopNavBar';
import { BrandingOnlyTopNavBar } from '@csmsce/msx-platform/lib/TopNavBar/BrandingOnlyTopNavBar';
import { LeftNavbar } from '@csmsce/msx-platform/lib/LeftNavBar/LeftNavBar';
import { Styles } from './App.styles';
import {
    View, MessageBarConfiguration, SplashPageConfiguration, StandardView, Layout, TopNavBarMex
} from '@csmsce/msx-types';
import { ViewRenderer } from '@csmsce/msx-platform/lib/Views/ViewRenderer';
import { FloatViewRenderer } from '@csmsce/msx-platform/lib/Views/FloatView';
import { SplashPage } from '../SplashPage/SplashPage';
import { NoRolePage } from '@csmsce/msx-platform/lib/NoRolePage/NoRolePage';
import { TopBar } from '@csmsce/msx-platform/lib/TopBar/TopBar';

const topCommonWindow = findTopSameOriginWindow();

/**
 * Props for App
 */
export interface IAppProps {
    navigationContext: INavigationContext;
    /**
     * enum value to check whether we should render chromeless page or only show branding or show full components
     */
    headerType: HeaderType;
    /**
     * The instance id for the current view
     */
    viewInstanceId: string;
    /**
     * Additional topNavBar mexs to render
     */
    topNavBarMexs?: TopNavBarMex[];

    /**
     * the name of the current experience
     */
    experience: string;
}

/**
 * State for App
 */

export interface IAppState {
    /**
     * Specifies whether we need to show messageBar
     */
    showMessageBar: boolean;

    view: StandardView | undefined;

    viewLoadStatus: 'Loading' | 'Loaded' | 'Error';

    extraButtons: ShellButton[];
    backButtonOverride: (() => void) | undefined;
    forceHideBackButton: boolean;
}

export class App extends React.Component<IAppProps, IAppState> {

    private shellContext: ShellContext;

    telemetryContext: MexTelemetryContext;

    constructor(props: IAppProps) {
        super(props);
        this.telemetryContext = this._getTelemetryContext();
        this.state = {
            showMessageBar: true,
            viewLoadStatus: 'Loading',
            view: undefined,
            extraButtons: [],
            backButtonOverride: undefined,
            forceHideBackButton: false
        };
        this.shellContext = {
            name: window['mexextshell_hostname'] ? 'MEXExtensibleShell_' + window['mexextshell_hostname'] : 'msx-shell',
            experience: window['mexextshell_viewname'] ? 'MEXExtensibleShell_' + window['mexextshell_viewname'] : props.experience,
            isInIframe: window !== window.top,
            isPcf: false,
            isCrossOrigin: window.top !== topCommonWindow,
            navigationModesSupported: ShellNavigationModeFlags.Url,
            navigationSupported: true,
            extraButtons: {
                supported: true,
                add: this.addExtraButton,
                remove: this.removeExtraButton,
                hideBackButton: this.hideBackButton,
                overrideBackButtonAction: this.overrideBackButtonAction
            }
        };
        loadTheme(CoherenceTheme);
        TelemetryService.updateEnvelopeData({
            Shell: this.shellContext.name,
            Experience: this.shellContext.experience,
            IsInIframe: this.shellContext.isInIframe,
            IsPcf: this.shellContext.isPcf,
            IsCrossOrigin: this.shellContext.isCrossOrigin
        });
        this._loadView(props.navigationContext.currentView, true);
    }

    componentDidUpdate(prevProps: IAppProps) {
        this.setPageTitle(this.state.view, this.state.viewLoadStatus);
        if (prevProps.navigationContext.currentView !== this.props.navigationContext.currentView) {
            resetSupportContext();
            this._loadView(this.props.navigationContext.currentView, false);
        }
    }

    private setPageTitle(view: StandardView | undefined, viewLoadStatus: 'Loading' | 'Loaded' | 'Error') {
        const prefix = ConfigService.get('PageTitlePrefix') || 'MSX Portal';
        if (!window['mexextshell_viewname']) {
            if (!view) {
                if (viewLoadStatus === 'Error') {
                    document.title = prefix + ' | Error';
                } else {
                    document.title = prefix + ' | Loading...';
                }
            } else {
                document.title = prefix + ' | ' + view.displayName;
            }
        }
    }

    private async _loadView(newView: string, skipInitialSetState: boolean) {
        this.setPageTitle(undefined, 'Loading');
        if (!skipInitialSetState) {
            this.setState({
                view: undefined,
                viewLoadStatus: 'Loading',
                extraButtons: [],
                backButtonOverride: undefined,
                forceHideBackButton: false
            });
        }
        try {
            const view = await LayoutService.getSingleViewLazy(newView, this.telemetryContext);
            this.telemetryContext = this._getTelemetryContext();
            // set page title
            this.setPageTitle(view, 'Loaded');
            this.setState({
                view: view,
                viewLoadStatus: 'Loaded'
            });
        } catch (e) {
            this.setPageTitle(undefined, 'Error');
            TelemetryService.trackException(e, this.telemetryContext, { _viewName: newView });
            this.setState({
                view: undefined,
                viewLoadStatus: 'Error'
            });
        }
    }


    render() {
        let appBody = this.props.headerType === HeaderType.chromeless ? css(Styles.body, Styles.headerDisabled) : css(Styles.body, Styles.headerEnabled);
        return (
            <ErrorBoundary errorScope={ErrorScope.Global} onException={TelemetryService.trackException} debugValue={ConfigService.get('Debug') === 'true'}>
                <NavigationContext.Provider value={this.props.navigationContext}>
                    <ThemeProvider {...CoherenceCustomizations}>
                        {this._renderHeader()}
                        <div className={appBody}>
                            {this._renderLeftNavigation()}
                            {this._renderContentArea()}
                        </div>
                        {this._renderFloatingView()}
                        <TeachingBubbleQueueRenderer />
                    </ThemeProvider >
                </NavigationContext.Provider>
            </ErrorBoundary >
        );
    }

    private _renderHeader(): React.ReactNode {
        let {
            headerType,
            navigationContext,
            topNavBarMexs
        } = this.props;
        let pageName = this.state.view?.displayName;
        if (!pageName) {
            if (this.state.viewLoadStatus === 'Loading') {
                pageName = 'Loading...';
            } else {
                pageName = 'Error';
            }
        }
        switch (headerType) {
            case HeaderType.chromeless:
                return null;
            case HeaderType.brandingOnly:
                return (
                    <BrandingOnlyTopNavBar currentPage={pageName} />
                );
            default:
                return (
                    <TopNavBarConnect
                        navigationContext={navigationContext}
                        telemetryContext={this._getTelemetryContext()}
                        shellContext={this.shellContext}
                        pageName={pageName}
                        topNavBarMexs={topNavBarMexs}
                    />
                );
        }
    }

    private _renderLeftNavigation(): React.ReactNode {
        let {
            headerType
        } = this.props;
        if (headerType === HeaderType.full) {
            return (
                <div aria-label={'Left Navigation Bar'} className={Styles.sidenavbar}>
                    <LeftNavbar navigationContext={this.props.navigationContext} />
                </div>
            );
        } else {
            return null;
        }
    }

    private _renderContentArea(): React.ReactNode {
        let {
            headerType,
            navigationContext
        } = this.props;

        const showMessageOrSplash = (headerType === HeaderType.full || headerType === HeaderType.brandingOnly);

        this.telemetryContext = this._getTelemetryContext();

        let layout = LayoutService.getLayout(this.telemetryContext);

        let messageBarConfiguration: MessageBarConfiguration | undefined = layout.messageBarConfiguration;
        let splashPageConfiguration: SplashPageConfiguration | undefined = layout.splashPageConfiguration;

        return (
            <main tabIndex={-1} role="main" id="mexrenderer" className={window['mexextshell_hostname'] ? Styles.contentExtShell : Styles.content} >
                {!window['mexextshell_hostname'] &&
                    <TopBar
                        backButtonEnabled={!navigationContext.isRoot && !this.state.forceHideBackButton || !!this.state.backButtonOverride}
                        onBackButtonPressed={this.onBackButton}
                        extraButtons={this.state.extraButtons}
                    />
                }
                {showMessageOrSplash && messageBarConfiguration && this.state.showMessageBar && this._renderMessageBar(messageBarConfiguration!)}
                {showMessageOrSplash && this._renderSplashPage(splashPageConfiguration)}
                {this._renderMainContentSection(layout)}
            </main>
        );
    }

    private _renderMainContentSection(layout: Layout) {
        const {
            viewLoadStatus,
            view
        } = this.state;
        if (viewLoadStatus === 'Loading') {
            return (
                <div>
                    <Spinner size={SpinnerSize.large} label={'Loading view...'} />
                </div>
            );
        } else if (viewLoadStatus === 'Loaded' && view) {
            // Check if no role mapping experience is expected and if users haven't added any custom views after being shown no role experience
            if (layout.noRoleMapping && view.layoutType === 'tabLayout' && view.tabs.length <= 1) {
                return this._renderNoRoleView();
            } else {
                return this._renderView(view);
            }
        } else {
            return (
                <ErrorMessage message={'We were unable to load this view. Please try refreshing the page, or '} />
            );
        }
    }

    private _renderFloatingView(): React.ReactNode {
        let floatingView = LayoutService.getLayout(this.telemetryContext).floatingView;
        if (floatingView) {
            return (
                <FloatViewRenderer
                    view={floatingView}
                    viewContext={{
                        ...this.telemetryContext,
                        ViewName: floatingView.name
                    }}
                    navigationContext={this.props.navigationContext}
                    key={floatingView.id}
                    setTabbingEnabled={undefined}
                    updateExtraButtons={undefined}
                    shellContext={this.shellContext}
                />
            );
        } else {
            return null;
        }
    }

    /**
     * Render splash page DxpModal
     * @param splashPageConfiguration splash page configuration from layout
     */
    private _renderSplashPage(splashPageConfiguration?: SplashPageConfiguration): React.ReactNode {
        if (!splashPageConfiguration) {
            return null;
        }
        return (
            <SplashPage
                config={splashPageConfiguration}
                telemetryContext={this.telemetryContext}
            />
        );
    }

    /**
     * Render messagebar
     * @param messageBarConfiguration messagebar configuration
     */
    private _renderMessageBar(messageBarConfiguration: MessageBarConfiguration): React.ReactNode {
        let {
            buttons, contentString, messageBarType
        } = messageBarConfiguration;
        return (
            <div id='messageBarContainer' className={Styles.messageBarContainer}>
                <MessageBar
                    actions={
                        <div>
                            {buttons.map((button, index) => {
                                return (
                                    <MessageBarButton key={`messageBarButton-${index}`} onClick={() => {
                                        TelemetryService.trackUserAction(
                                            SdkEvents.App.ClickButtonInMessageBar,
                                            this.telemetryContext,
                                            {
                                                actionDetails: 'User clicked on message bar button',
                                                selectionDetails: button.buttonText,
                                                _buttonText: button.buttonText,
                                                _buttonUrl: button.buttonUrl
                                            }
                                        );
                                        window.open(button.buttonUrl);
                                    }}>
                                        {button.buttonText}
                                    </MessageBarButton>);
                            })}
                        </div>
                    }
                    dismissButtonAriaLabel="Close MessageBar"
                    onDismiss={() => {
                        TelemetryService.trackUserAction(
                            SdkEvents.App.CloseMessageBar,
                            this.telemetryContext,
                            {
                                actionDetails: 'User dismissed message bar',
                                selectionDetails: ''
                            }
                        );
                        this.setState({ showMessageBar: false });
                    }}
                    messageBarType={messageBarType}
                    isMultiline={false}
                >
                    {renderContentStringWithLink(contentString, () => TelemetryService.trackUserAction(
                        SdkEvents.App.ClickContentLinkInMessageBar,
                        this.telemetryContext,
                        {
                            actionDetails: 'User clicked on message bar content',
                            selectionDetails: ''
                        }
                    ))}
                </MessageBar>
            </div>
        );
    }

    private _renderNoRoleView(): React.ReactNode {
        return (
            <NoRolePage
                telemetryContext={this.telemetryContext}
            />
        );
    }

    private _renderView(view: View): React.ReactNode {
        this.telemetryContext = {
            ...this.telemetryContext,
            ViewName: view.name || 'View name not found'
        };
        return (
            <ViewRenderer
                navigationContext={this.props.navigationContext}
                view={view}
                viewContext={this.telemetryContext}
                setTabbingEnabled={undefined}
                updateExtraButtons={undefined}
                shellContext={this.shellContext}
            />
        );
    }

    /**
     * Gets the telemetry context
     */
    private _getTelemetryContext(): MexTelemetryContext {
        return {
            ViewName: this.props.navigationContext.currentView,
            ViewInstanceId: this.props.viewInstanceId,
            MexName: 'Platform/App',
            MexInstanceId: 'N/A',
            MaskedUserId: UserMappingService.get().MaskedUserId,
            ComponentId: MSXPlatformComponent.id,
            ComponentName: MSXPlatformComponent.name,
            UserSessionId: window['mexextshell_correlationid'] ? window['mexextshell_correlationid'] : TelemetryService.getXcv()
        };
    }

    private addExtraButton = (button: ShellButton) => {
        this.setState(state => {
            const newButtons = Array.from(state.extraButtons);
            const index = newButtons.findIndex(x => x.id === button.id);
            if (index > -1) {
                newButtons[index] = button;
            } else {
                newButtons.push(button);
            }
            return { extraButtons: newButtons };
        });
    }

    private removeExtraButton = (id: string) => {
        this.setState(state => {
            // we do a seemingly-redundant check first because it's cheaper than an unnecessary re-render
            if (state.extraButtons.find(x => x.id === id)) {
                return { extraButtons: state.extraButtons.filter(x => x.id !== id) };
            }
            return null;
        });
    }

    private hideBackButton = () => {
        this.setState({ forceHideBackButton: true });
    }

    private overrideBackButtonAction = (action: (() => void) | undefined) => {
        this.setState({ backButtonOverride: action });
    }

    private onBackButton = () => {
        if (this.state.backButtonOverride) {
            this.state.backButtonOverride();
        } else {
            this.props.navigationContext.pop(this.telemetryContext);
        }
    }
}
