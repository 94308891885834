import { mergeStyleSets, IStyle } from '@fluentui/react';
import { Colors } from '@csmsce/msx-components';

export const Styles = mergeStyleSets({
    body: {
        padding: 0,
        margin: 0,
        listStyle: 'none',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        alignItems: 'stretch'
    } as IStyle,
    headerEnabled: { height: 'calc(100vh - 48px)' } as IStyle,
    headerDisabled: { height: '100vh' } as IStyle,
    sidenavbar: {
        order: 1,
        flexShrink: 1,
        alignSelf: 'stretch',
        display: 'flex'
    } as IStyle,
    content: {
        position: 'relative',
        background: Colors.Application.NeutralBackground,
        order: 2,
        flexGrow: 1,
        alignSelf: 'stretch',
        overflowY: 'auto',
        '-webkit-overflow-scrolling': 'touch'
    } as IStyle,
    contentExtShell: {
        position: 'relative',
        height: 'max-content',
        background: Colors.Application.NeutralBackground,
        order: 2,
        flexGrow: 1,
        alignSelf: 'stretch',
        overflowY: 'auto',
        '-webkit-overflow-scrolling': 'touch'
    } as IStyle,
    modalGrid: {
        display: 'flex',
        flexDirection: 'row',
        height: '413px',
        width: '1048px'
    } as IStyle,
    modalLeftSide: {
        display: 'flex',
        width: '788px',
        flexDirection: 'column',
        marginTop: '100px',
        marginLeft: '80px',
        marginRight: '19px'
    } as IStyle,
    modalRightSide: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: '97px',
        marginLeft: '97px',
        marginTop: '59px',
        marginBottom: '69px'
    } as IStyle,
    modalContentText: {
        marginTop: '5px',
        marginBottom: '40px'
    } as IStyle,
    messageBarContainer: {
        position: 'sticky',
        top: 0,
        zIndex: 100
    } as IStyle
});