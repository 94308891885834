/**
 * LaunchOptions interfaces
 */

import {ICategoryOptions} from "./ICategoryOptions";
import {IManifestDataApplication, IManifestDataTelemetry, IManifestDataWeb} from "./../Transport/Transport";

/**
 * Interface for launch options for inAppFeedback
 */
export interface ILaunchOptionsInAppFeedback {
	applicationGroup?: IManifestDataApplication;
	telemetryGroup?: IManifestDataTelemetry;
	webGroup?: IManifestDataWeb;
	categories?: ICategoryOptions;
}
