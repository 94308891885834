import type { IProcessedStyleSet, IRenderFunction } from '@fluentui/react';
import { classNamesFunction } from '@fluentui/react';
import { M365Breadcrumb } from '@m365-admin/m365-breadcrumb';
import * as React from 'react';

import { getStepToShow } from '../utilities/index';
import type { IWizardParentState } from '../wizard/index';
import { Wizard } from '../wizard/index';
import type {
  IFullPageWizardProps,
  IFullPageWizardStyleProps,
  IFullPageWizardStyles,
} from './full-page-wizard.types';

const getClassNames = classNamesFunction<
  IFullPageWizardStyleProps,
  IFullPageWizardStyles
>();

export const FullPageWizardBase = (
  props: IFullPageWizardProps,
  state: IWizardParentState,
): JSX.Element => {
  const [isNarrow, setIsNarrow] = React.useState(state.isNarrow);

  const _onRenderTitle: IRenderFunction<IFullPageWizardProps> = (fullPageWizardProps) => {
    if (fullPageWizardProps?.breadcrumbProps) {
      return (
        <M365Breadcrumb
          {...fullPageWizardProps.breadcrumbProps}
          styles={classNames.subComponentStyles.breadcrumb}
        />
      );
    }

    if (title) {
      return <div className={classNames.title}>{title}</div>;
    }

    return null;
  };

  const {
    onRenderTitle = _onRenderTitle,
    title,
    breadcrumbProps,
    styles,
    theme,
    wizardProps,
  } = props;

  const classNames: IProcessedStyleSet<IFullPageWizardStyles> = getClassNames(styles, {
    theme: theme!,
    isNarrow: isNarrow,
    isLoading: !!wizardProps.isLoading,
  });

  const step = getStepToShow(wizardProps);

  const onIsNarrowChanged = (newNarrowValue: boolean): void => {
    setIsNarrow(newNarrowValue);

    if (wizardProps?.onIsNarrowChanged) {
      wizardProps.onIsNarrowChanged(newNarrowValue);
    }
  };

  if (wizardProps.styles) {
    console.warn(
      'It looks like you have passed in styles to the wizard. This will be over written by FullPageWizard.styles.subComponentStyles.wizard. If you would like to change the wizard styles here, it will be safest to do so via sub component styles.',
    );
  }

  if (title && breadcrumbProps) {
    console.warn(
      'It looks like you have passed in both a title and breadcrumbProps. Title will be overridden by breadcrumbProps. Please choose one or the other.',
    );
  }

  if (breadcrumbProps?.styles) {
    console.warn(
      'It looks like you passed in breadcrumb.styles. These will be overwritten. Please use FullPageWizardProps.styles.subComponentStyles.loadingPane instead.',
    );
  }

  return (
    <div className={classNames.root}>
      <div className={classNames.headerContainer}>
        {onRenderTitle(props, _onRenderTitle)}
      </div>
      <Wizard
        {...wizardProps}
        stepToShow={step}
        styles={classNames.subComponentStyles.wizard}
        onIsNarrowChanged={onIsNarrowChanged}
      />
      <div className={classNames.footerContainer}>{step.footerElement}</div>
    </div>
  );
};
