import { getWizardTitleStyles, wizardBreadcrumbStyles } from '../wizard/index';
import type { ISetupWizardStyleProps, ISetupWizardStyles } from './setup-wizard.types';

const componentName = 'SetupWizard';

export const getSetupWizardStyles = (
  props: ISetupWizardStyleProps,
): ISetupWizardStyles => {
  const { isNarrow, isLoading, theme } = props;

  return {
    wizardContainer: [
      theme.fonts.medium,
      {
        height: isNarrow && !isLoading ? 'unset' : '100%',
        display: 'flex',
        flexDirection: 'column',
      },
    ],
    titleSection: [
      getWizardTitleStyles({ theme, componentName, isNarrow }),
      { borderBottom: `1px solid ${theme.semanticColors.bodyDivider}` },
    ],
    actionBarSection: {
      borderTop: `1px solid ${theme.semanticColors.bodyDivider}`,
    },
    subComponentStyles: {
      wizard: {
        subwayNavSection: { border: '0px' },
      },
      breadcrumb: wizardBreadcrumbStyles,
    },
  };
};
