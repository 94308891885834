import * as Constants from "./../Constants";
import { IOnDismissDelegate } from "./../IOnDismissDelegate";
import * as UI from "./../UI/UI";
import { IAugLoopCallback, IFloodgateAuthTokenCallback, IFloodgateSettingStorageCallback, IOnSurveyActivatedCallback }
	from "@ms-ofb/officefloodgatecore/dist/src/Api/Api";
import { CampaignDefinition } from "@ms-ofb/officefloodgatecore/dist/src/Campaign/CampaignDefinitionProvider";
import { IManifestDataApplication, IManifestDataTelemetry, IManifestDataWeb } from "./../Transport/Transport";
import { ISession } from "@augloop/runtime-client";
import { ICVSurveyParams } from "@ms-ofb/officefloodgatecore/dist/src/Api/ICVSurvey";

/**
 * Interface for common initialization options
 */
export interface IInitOptionsCommon {
	// An id uniquely identifying the host app in the OCV world
	appId: number;
	// The url at which the styles reside
	stylesUrl?: string;
	// The url at which the internationalization files reside
	intlUrl?: string;
	// The filename for the internationalization strings, if different than the default filename
	intlFilename?: string;
	// Base url where any ux assets reside. Mainly used by coaching ux
	webSurfacesResourceBaseUrl?: string;
	// Environment
	environment: Constants.Environment;
	// A string uniquely identifying the current session
	sessionId?: string;
	// cid string which is in-scope for GDPR compliance for export and deletion request
	cid?: string;
	// A build version for your app
	build?: string;
	// The primary colour in hex form #[0-9a-f]{6}
	primaryColour?: string;
	// The secondary colour in hex form #[0-9a-f]{6}
	secondaryColour?: string;
	// The UI locale name of the calling page
	locale?: string;
	// System-Use only
	originalLocale?: string;
	// A callback which will be called when the SDK encounters errors
	onError?: (err: string) => void;
	// Application related metadata
	applicationGroup?: IManifestDataApplication;
	// Telemetry related metadata
	telemetryGroup?: IManifestDataTelemetry;
	// Web related metadata
	webGroup?: IManifestDataWeb;
	// User Email 
	userEmail?: string;
	// The switch to show checkbox checked or unchecked
	userEmailConsentDefault?: boolean;
	// The json string to define event sampling
	eventSampling?: ISamplingEvent[];
	// The timeout for sending response in petrol
	petrolTimeout?: number;
	// check to see if user is consumer/commercial to dynamically display UI
	isCommercialHost?: boolean;
	// Option to see if the host will be providing their own resources such as strings or CSS
	customResourcesSetExternally?: CustomResources;
	// the email policy value from OCPS to determines if the user can see an option to include their email when they submit feedback
	emailPolicyValue?: Constants.OCPSValues;
	// the screenshot policy value from OCPS to determines if the user can see an option to include their screenshot when they submit feedback
	screenshotPolicyValue?: Constants.OCPSValues;
	// AgeGroup enum which user is classfied as
	ageGroup?: AgeGroup;
	// Authentication Type of user
	authenticationType?: AuthenticationType;
	// Application settings
	applicationSettings?: IApplicationSettings;
	// Package settings for the new web surfaces urls (after migration of the CDN)
	webSurfacesSettings?: IWebSurfacesSettings;
}

/**
 * Interface for package settings
 */
export interface IWebSurfacesSettings {
	// Internal web surfaces url
	webSurfacesIntUrl?: string;
	// Production web surfaces url 
	webSurfacesProdUrl?: string;
}

/**
 * Interface for host application settings
 */
export interface IApplicationSettings {
	readonly [key: string]: unknown;
}

/**
 * Interface for inAppFeedback initialization options
 */
export interface IInitOptionsInAppFeedback {
	// Method called on feedback submission
	onDismiss?: IOnDismissDelegate;
	// Bug form toggle
	bugForm?: boolean;
	// email enabled
	showEmailAddress?: boolean;
	// User email
	userEmail?: string;
	// Screenshot toggle
	screenshot?: boolean;
	// User voice related options.
	userVoice?: UI.IUserVoiceInitOptions;
	// The switch to show a thank you panel
	isShowThanks?: boolean;
	// Flag to enable/disable transistion on the UI.
	transitionEnabled?: boolean;
	// the feedback policy value from OCPS to determines if the user can submit feedback
	sendFeedbackPolicyValue?: Constants.OCPSValues;
	// feedbackPortal URL
	feedbackForumUrl?: string;
}

/**
 * Interface for floodgate initialization options
 */
export interface IInitOptionsFloodgate {
	// Should the floodgate prompt automatically dismiss if not clicked? Default is false
	autoDismiss?: Constants.AutoDismissValues;

	// Campaign definitions
	campaignDefinitions?: CampaignDefinition[];

	// Flights for TMS service
	campaignFlights?: string;

	// Additional query parameters for TMS service
	campaignQueryParameters?: string;

	// Delegate to be called when the floodgate UI is dismissed, whether on a submission or not
	onDismiss?: (campiagnId: string, submitted: boolean) => void;

	// The callback to be executed when a survey is activated
	onSurveyActivatedCallback?: IOnSurveyActivatedCallback;

	// The callback to be executed when host-based data operations are needed
	settingStorageCallback?: IFloodgateSettingStorageCallback;

	// Optionally provide a method which will accept the custom strings in Campaign Definitions and return actual
	// strings that must be displayed. Used for localization.
	uIStringGetter?: (str: string) => string;

	// The callback to be executed when host-based auth token is needed
	authTokenCallback?: IFloodgateAuthTokenCallback;

	// The boolean option to enforce if surveys are allowed by a policy, such as an OCPS policy for a tenant.
	// True: Surveys are enabled, floodgate works normally; False: Surveys are disabled
	surveyEnabled?: boolean;

	// The boolean option to enforce if email address collection in a survey is allowed by a policy, such as an OCPS policy for a tenant.
	// True: email address collection is enabled, floodgate works normally; False: email address collection is disabled
	showEmailAddress?: boolean;

	// Interface to enable/disable and pass augLoop host references
	augLoopCallback?: IAugLoopCallback<ISession>;

	// Interface to pass the params for the Customer Voice Survey
	customerVoiceSurveyParams?: ICVSurveyParams;

	// The boolean option to enabl the governance service
	governanceServiceEnabled?: boolean;

	// configuration for governance service
	governanceServiceConfig?: IGovernanceServiceConfig;

	// is personalizer enabled on client side.
	personalizerEnabled?: boolean;
}

/**
 * Interface for floodgate event sampling options
 */
export interface ISamplingEvent {
	type: string;
	name: string;
	sampleRate: number;
}

export interface ISamplingInitOption {
	event?: Record<string, number>;
}

export interface IGovernanceServiceConfig {
	// limit number of retry
	retry?: number;

	// timeout in second
	timeout?: number;

	// In case of failure call, option to whether continue display survey or fall off
	forceServicelessSurveyDisplay?: boolean;

	// Option to use simple request
	simpleRequestEnabled?: boolean;
}

// bit mask flag: values should be set 0,1,2,4,8,16, etc...
// Example for Css and Strings both, use 3.
export enum CustomResources {
	None = 0,
	Css = 1,
	Strings = 2,
}

export enum AgeGroup {
	Undefined​, // Unknown age
	MinorWithoutParentalConsent, // Minor under the age of consent
	MinorWithParentalConsent, // Minor under age of consent
	NotAdult, // Minor above age of consent
	Adult, // Adult
	MinorNoParentalConsentRequired, // Minor above age of consent
}

export enum AuthenticationType {
	MSA,
	AAD,
	Unauthenticated
}

// Taken from %SRCROOT%\privacy\options\public\UserConsentGroup.h
// Enum that describes the different consent categories of the user.
export const enum UserConsentGroup {
	// Unknown consent group, we do not have info to determine this
	Unknown = 0,
	// Can give consent to data collection, upsell messages
	CanConsent = 1,
	// Can still give consent but is only a limited consent.
	// As an example, upsell / cross sell should not be shown.
	PartialConsent = 2,
	// They cannot provide any form of consent. No consent dialogs / upsell should not be shown.
	CannotConsent = 3,
};
