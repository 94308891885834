import { BootPlatform } from '@csmsce/msx-bootstrapper';
import { ErrorBoundary, ErrorMessage, ErrorScope } from '@csmsce/msx-components';
import { configureStore, EnableGlobalCaching, Status } from '@csmsce/msx-redux';
import {
    AuthenticationService, ConfigService, findTopSameOriginWindow, initApolloClientDefaults,
    isIE, LayoutService, MexTelemetryContext, MSXPlatformComponent, NotificationService,
    RoleService, TelemetryService, UserMappingService, UserSettingService, getErrorType
} from '@csmsce/msx-sdk';
import * as Highcharts from 'highcharts';
import accessibility from 'highcharts/modules/accessibility';
import { initializeIcons, IProgressIndicatorStyles, ProgressIndicator } from '@fluentui/react';
import * as React from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { AppRouter } from './Components/AppRouter/AppRouter';
import { InitGlobalModules } from './config/InitGlobalModules';
import { PortalState } from './models/portal.types';
import { progressBarStyle, progressContainer } from './portal.styles';
import { NonMicrosoftEmailPage } from '@csmsce/msx-platform/lib/NonMicrosoftEmailPage/NonMicrosoftEmailPage';

// This enables the highcharts accessibility module in all charts in the portal.
accessibility(Highcharts);

const bootstrapTelemetryContext: MexTelemetryContext = {
    MexName: 'Platform/Bootstrap',
    MexInstanceId: 'N/A',
    ViewInstanceId: 'N/A',
    ViewName: 'N/A',
    ComponentId: MSXPlatformComponent.id,
    ComponentName: MSXPlatformComponent.name
};

const isUnsupportedBrowser = isIE();

const observer = TelemetryService.initializePerformanceObserver(bootstrapTelemetryContext);

InitGlobalModules();

type EventStatus = 'Success' | 'Failed';

const topCommonWindow = findTopSameOriginWindow();

class Portal extends React.Component<{}, PortalState, IProgressIndicatorStyles> {
    private store: Store<any>;

    private pageLoadStartTime = 0;

    private configServiceDuration = 0;

    private fetchUserMappingDuration = 0;

    private fetchRoleDuration = 0;

    private fetchUserSettingDuration = 0;

    private fetchLayoutDuration = 0;

    constructor(props: {}) {
        super(props);
        this.pageLoadStartTime = Date.now();
        this.state = {
            serviceCallStatus: Status.NotCalled,
            serviceCallError: undefined,
            isMicrosoftEmail: true
        };
    }

    componentDidMount() {
        if (!isUnsupportedBrowser) {
            ConfigService.addEventListener('change', this.refreshApp.bind(this));
            LayoutService.addEventListener('update', this.refreshApp.bind(this));
            RoleService.addEventListener('LayoutValueChange', this.bootLayoutService.bind(this));
            this.boot();
        }
    }

    componentWillUnmount() {
        ConfigService.removeEventListener('change', this.refreshApp.bind(this));
        LayoutService.removeEventListener('update', this.refreshApp.bind(this));
        RoleService.removeEventListener('LayoutValueChange', this.bootLayoutService.bind(this));
        observer?.disconnect();
    }

    render() {
        let {
            serviceCallStatus, isMicrosoftEmail
        } = this.state;
        if (isUnsupportedBrowser) {
            return (
                <div style={{ height: '100vh' }}>
                    <ErrorMessage
                        iconName={'IncidentTriangle'}
                        message={'We don\'t currently support Internet Explorer. Try opening this page in a different browser.'}
                        link={null}
                    />
                </div>
            );
        }

        if (serviceCallStatus === Status.Loaded) {
            if (!isMicrosoftEmail) {
                return (
                    <div style={{ height: '100vh' }}>
                        <ErrorBoundary errorScope={ErrorScope.Global} onException={TelemetryService.trackException} debugValue={false}>
                            <NonMicrosoftEmailPage
                                telemetryContext={bootstrapTelemetryContext}
                            />
                        </ErrorBoundary>
                    </div>
                );
            }
            return (
                <ErrorBoundary errorScope={ErrorScope.Global} onException={TelemetryService.trackException} debugValue={ConfigService.get('Debug') === 'true'}>
                    <Provider store={this.store}>
                        <AppRouter experience={this.getExperienceName()} />
                    </Provider>
                </ErrorBoundary>
            );
        } else if (serviceCallStatus === Status.Error) {
            // Will change it later after we refactor ErrorMessage and ErrorBoundary
            return (
                <div style={{ height: '100vh' }}>
                    <ErrorMessage
                        iconName={'IncidentTriangle'}
                        message={' Something went wrong! Please try refreshing the page. If that doesn’t help,'}
                        link={{
                            linkText: 'submit a ticket',
                            linkAction: () => window.open(process.env.SERVICENOW_URL)
                        }}
                    />
                </div>
            );
        } else {
            return (
                this.getProgressIndicator()
            );
        }
    }

    private getProgressIndicator() {
        let labelText = !window['mexextshell_hostname'] ? 'We\'re getting MSX ready for you...' : 'Loading';
        return (
            <div className={progressContainer}>
                <ProgressIndicator
                    styles={progressBarStyle}
                    label={labelText}
                />
            </div>
        );
    }

    private async boot() {
        // we have to init the fabric icons way up here in case something errors down below
        initializeIcons();

        try {
            // we use msx-bootstrapper code to boot the first few services
            let initBootResult = await BootPlatform(true);
            if (initBootResult.status === 'Error') {
                this.onError(initBootResult.error, initBootResult.failingComponent);
                return;
            }
            // add performance observer
            observer?.observe({ entryTypes: ['resource', 'longtask'] });

            if (initBootResult.isAuthRedirect) {
                return;
            }

            if (!initBootResult.isMicrosoftEmail) {
                this.setState({
                    serviceCallStatus: Status.Loaded,
                    isMicrosoftEmail: false
                });
                this.logBootstrapEvent('Success');
                return;
            }

            // fetch token for pre defined set of resources
            try {
                const gqlScope = AuthenticationService.findScope(process.env.UCS_URL!);
                if (gqlScope?.[0]) {
                    const graphToken = await AuthenticationService.acquireTokenForScope(gqlScope[0]!);
                    graphToken && TelemetryService.trackInfo('Preloaded graphql token', bootstrapTelemetryContext);
                }
            } catch (e) {
                this.onError(e, 'Token Preload');
                return;
            }

            // initialize config service
            try {
                this.configServiceDuration = await ConfigService.initialize(this.getExperienceName());
            } catch (e) {
                this.onError(e, 'ConfigService');
                return;
            }

            if (!window['mexextshell_hostname']) {
                document.title = ConfigService.get('PageTitlePrefix');
            }

            // now that we have the config service, we can start booting the rest, including some in parallel
            let userMappingPromise = UserMappingService.initialize(this.refreshApp);
            // do some syncronous boot things while waiting on the user mapping stuff
            /* Integrating Redux Store to Application */
            this.bootRedux();

            // boot apollo client
            this.bootApollo();

            try {
                this.fetchUserMappingDuration = await userMappingPromise;
            } catch (e) {
                this.onError(e, 'UserMappingService');
                return;
            }

            let userSettingPromise = UserSettingService.initialize(this.refreshApp);
            let rolePromise = RoleService.initialize(true, this.refreshApp);

            try {
                this.fetchRoleDuration = await rolePromise;
            } catch (e) {
                this.onError(e, 'RoleService');
                return;
            }

            // now that we have the user profile, we can kick off the layout service boot (the last async service boot)
            let layoutPromise = this.bootLayoutService();

            try {
                this.fetchUserSettingDuration = await userSettingPromise;
            } catch (e) {
                this.onError(e, 'UserSettingService');
                return;
            }
            try {
                this.fetchLayoutDuration = await layoutPromise;
            } catch (e) {
                this.onError(e, 'LayoutService');
                return;
            }

            // initialize notification service, don't wait since its not necessary for startup
            if (process.env.ENVIRONMENT !== 'prod') {
                NotificationService.initialize();
            }

            // initialize SupportContext
            (window as any).SupportContext = {};
        } catch (e) {
            this.onError(e);
            return;
        }

        this.setState({ serviceCallStatus: Status.Loaded });
        this.logBootstrapEvent('Success');
    }

    private getExperienceName(): string {
        let url = topCommonWindow.location.origin.toLowerCase();
        let experienceName: string | undefined = undefined;
        let experiences = JSON.parse(process.env.EXPERIENCE_URLS || '[]');
        for (let experience of experiences) {
            if (url.includes(experience.url)) {
                experienceName = experience.name;
                break;
            }
        }
        experienceName = experienceName || localStorage.getItem('MSX_EXPERIENCE_OVERRIDE') || 'MsxPortal';
        return experienceName;
    }

    private bootApollo() {
        initApolloClientDefaults(
            AuthenticationService.acquireToken,
            ConfigService.get('UCS_URL_OVERRIDE') || process.env.UCS_URL!,
            ConfigService.get('UCS_SUBSCRIPTIONKEY_OVERRIDE') || process.env.UCS_SUBSCRIPTIONKEY!,
            process.env.APP_ID || 'MSXPortal'
        );
    }

    private bootRedux() {
        this.store = configureStore();
        // enable redux global caching if requested in config
        if (ConfigService.get('EnableReduxGlobalCaching') === 'true') {
            EnableGlobalCaching();
        }
    }

    private onError(error: Error, serviceName?: string) {
        this.setState({
            serviceCallStatus: Status.Error,
            serviceCallError: error
        });
        let errorType = getErrorType([error]);
        TelemetryService.trackException(
            error,
            bootstrapTelemetryContext,
            {
                CustomErrorMessage: 'Error in initializing services',
                Service: serviceName,
                ErrorType: errorType
            }
        );
        this.logBootstrapEvent('Failed', serviceName, errorType);
    }

    private bootLayoutService() {
        // we need to find a possible layout override to send to the server
        let possibleLayoutOverride: string | undefined = undefined;
        const pathname = window.location.pathname;
        if (pathname !== '/') {
            possibleLayoutOverride = pathname.split('/')[1];
        }

        return LayoutService.initializeWithLayout(RoleService.get().MSXRole, this.getExperienceName(), possibleLayoutOverride);
    }

    private logBootstrapEvent = (status: EventStatus, failureReason?: string, errorType?: string) => {
        let duration = Date.now() - this.pageLoadStartTime;
        // Leaving this as event as we want XCV to be stamped as action correlation id for bootstrap
        TelemetryService.trackUserAction(
            {
                eventName: 'Platform Bootstrap',
                usageEventName: 'PageLoad',
                usageEventType: 'UserAction'
            },
            bootstrapTelemetryContext,
            {
                '_Status': status,
                '_IsSuccess': status === 'Success',
                '_Duration': duration,
                '_AuthDuration': window.MsxBootstrapper.status.timings.AuthenticationService,
                '_RoleDuration': this.fetchRoleDuration,
                '_UserMappingDuration': this.fetchUserMappingDuration,
                '_UserSettingDuration': this.fetchUserSettingDuration,
                '_ConfigDuration': this.configServiceDuration,
                '_LayoutCallDuration': this.fetchLayoutDuration,
                '_BootstrapperTotalDuration': window.MsxBootstrapper.status.totalDuration,
                '_BootstrapperResult': window.MsxBootstrapper.status.status,
                '_FailureReason': failureReason,
                '_ErrorType': errorType,
                actionDetails: 'Platform bootstrap success',
                selectionDetails: ''
            }
        );

        // Required for CUPP telemetry- One time log per session
        TelemetryService.trackSystemAction(
            {
                eventName: 'AwareAction',
                usageEventName: 'NA',
                usageEventType: 'AwareAction'
            },
            bootstrapTelemetryContext
        );
    }

    private refreshApp = () => {
        this.forceUpdate();
    }
}
export default Portal;